// set active button on click
function setActiveBtn(e) {
  const btns = e.target.parentElement.querySelectorAll(".buttons button");
  btns.forEach((btn) => {
    btn.classList.remove("btn-clicked");
  });
  // add active class to clicked button
  e.target.classList.add("btn-clicked");
}
function filterImg(e) {
  setActiveBtn(e);
  const { isfetch } = e.target.parentElement.parentElement.dataset;

  const imgs = e.target.parentElement.parentElement.querySelectorAll(".images .unItem");// eslint-disable-line
  // loop over all images
  imgs.forEach((img) => {
    // expand all images
    img.classList.add("img-shrink");
    img.classList.remove("img-expand");
    if (typeof isfetch === "undefined") {
      const imgType = img.dataset.img.split(",");
      const btnType = e.target.dataset.btn;
      if (imgType.includes(btnType)) {
        // hide the image
        img.classList.add("img-expand");
        img.classList.remove("img-shrink");
      }
    }
  });

  // fetch post per category
  if (typeof isfetch !== "undefined") {
    const parentDiv = e.target.parentElement.parentElement.querySelector(".hidden");// eslint-disable-line
    const baseDataSet = parentDiv.dataset;
    const { url } = baseDataSet;
    const { postsPerPage } = baseDataSet;
    const { postType } = baseDataSet;
    const { nonce } = parentDiv;

    const formData = new FormData();// eslint-disable-line
    formData.append("posts_per_page", postsPerPage);
    formData.append("post_type", postType);
    formData.append("nonce", nonce);
    formData.append("action", "block_filter");
    if (typeof e.target.dataset.btn !== "undefined") {
      formData.append("category", e.target.dataset.btn);
    }
    const params = new URLSearchParams(formData);
    // fetch the data
    fetch(url, {
      method: "POST",
      body: params,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response === 0 || response.status === "error") {
          // if error
          return;
        }

        const postDiv = e.target.parentElement.parentElement.querySelector(".images");// eslint-disable-line
        postDiv.innerHTML = response.posts;
      })
      .catch((error) => console.log("error =", error));// eslint-disable-line
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".block-filter");

  if (blocks.length === 0) {
    return;
  }
  [...blocks].forEach((block) => {
    const btns = block.querySelectorAll(".buttons button");
    btns.forEach((e) => {
      e.addEventListener("click", filterImg);
    });
    btns[0].addEventListener("click", (e) => {
      setActiveBtn(e);
      // expand all images
      const imgs = block.querySelectorAll(".images .unItem");
      imgs.forEach((img) => {
        img.classList.remove("img-shrink");
        img.classList.add("img-expand");
      });
    });
  });
});
